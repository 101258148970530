
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Multiselect from '@vueform/multiselect';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { DriverObject } from '@/store/modules/assets/DriversModule';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'driver-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedVehicle = ref<number>(0);
      const selectedDriver = ref<number>(0);

      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading');

      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');
      }, 1000);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(translate('driverUpdating'), [
          translate('assets'),
        ]);
        await store.dispatch(Actions.GET_ALL_AREAS, {
          driverId: driver.value.id,
        });
        await store.dispatch(Actions.GET_ALL_OFFICES, {
          driverId: driver.value.id,
        });
        await store.dispatch(Actions.GET_ALL_VEHICLES);
        await store.dispatch(Actions.GET_ALL_DRIVER_TYPES);
      });

      const goBack = () => {
        router.back();
      };

      const data = await store.dispatch(Actions.GET_DRIVER, route.params.id);

      const driver = ref<DriverObject>(data);
      const selectedArea = ref<string>(driver.value.areaIds);
      const selectedOffice = ref<string>(driver.value.officeIds);
      const getProfileImage = () => {
        const imageUrl = driver.value.getProfileImage;
        if (imageUrl) {
          return imageUrl;
        } else {
          return '/media/avatars/blank.png';
        }
      };

      await store.dispatch(Actions.GET_ALL_HUBS);

      const driverMobile = computed(() => {
        const mobileString = String(driver.value.mobile);
        const last7Digits = mobileString.slice(-10);
        return String(last7Digits);
      });

      const getLicenseImage = () => {
        const imageUrl = driver.value.getLicenseImage;
        if (imageUrl) {
          return imageUrl;
        } else {
          return '/media/avatars/blank.png';
        }
      };

      const driverSchema = Yup.object().shape({
        typeId: Yup.string()
          .required(() => translate('TYPE_IRE_IS_REQUIRED_FIELD'))
          .label('typeId'),
        vehicleId: Yup.string()
          .required(() => translate('VEHICLEID_IS_REQUIRED_FIELD'))
          .label('vehicleId'),
        mobile: Yup.string()
          .matches(/^(77|78|75)\d{8}$/, 'Mobile number is not valid')
          .required(() => translate('PHONE_IS_REQUIRED_FIELD'))
          .label('Mobile'),
        fullName: Yup.string()
          .required(() => translate('FULL_NAME_IS_REQUIRED_FIELD'))
          .label('Full Name'),
        email: Yup.string()
          .email(() => translate('EMAIL_NOT_VALID'))
          .required(() => translate('EMAIL_IS_REQUIRED_FIELD'))
          .label('Email'),
      });

      const onSubmitCreate = async (values) => {
        values.areasIds = selectedArea.value;
        values.officesIds = selectedOffice.value;

        values.mobile = String('964' + values.mobile);
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        let logoFormData: FormData | null = null;
        if (values.logo) {
          logoFormData = new FormData();
          logoFormData.append('images', values.logo);
        }

        let logoFormDataTow: FormData | null = null;
        if (values.image) {
          logoFormDataTow = new FormData();
          logoFormDataTow.append('images', values.image);
        }
        delete values.logo;
        delete values.image;
        values.isActive = true;
        const drivers = await store.dispatch(Actions.UPDATE_DRIVER, {
          data: values,
          id: driver.value?.id,
        });

        if (logoFormData) {
          await store.dispatch(Actions.UPLOAD_DRIVER_IMAGE, {
            data: logoFormData,
            driverId: driver.value?.id,
          });
        }
        if (logoFormDataTow) {
          await store.dispatch(Actions.UPLOAD_LICENSE_IMAGE, {
            data: logoFormDataTow,
            driverId: driver.value?.id,
          });
        }

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_DRIVER'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'drivers-listing' });
          });
        }
      };

      const offices = computed(() => store.getters.allOfficesList);

      const officesTitle = () => {
        return offices.value.map((r) => {
          return { title: r.titleTranslate, id: r.id };
        });
      };

      const areas = computed(() => store.getters.allAreasList);
      const areasTitle = () => {
        return areas.value.map((i) => {
          return { title: i.titleTranslate, id: i.id };
        });
      };
      return {
        vehicles: computed(() => store.getters.vehicleList),
        driverTypes: computed(() => store.getters.driverTypesList),
        hubs: computed(() => store.getters.hubsList),
        onSubmitCreate,
        getProfileImage,
        getLicenseImage,
        driverMobile,
        areas,
        submitButton,
        selectedArea,
        selectedOffice,
        officesTitle,
        areasTitle,
        translate,
        driverSchema,
        goBack,
        selectedVehicle,
        driver,
        selectedDriver,
        can,
      };
    },
  });
